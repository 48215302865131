<template>
    <div class="edit-project">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.projectUpdate')"
            :title="$t('messages.projectUpdate')"
            @submitPressed="submitPressed"
        />
        <project-form
            v-if="project"
            :projectObject="project"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ProjectForm from "./components/ProjectForm.vue";

export default {
    components: {
        TitleBar,
        ProjectForm
    },
    data() {
        return {
            project: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Projects.getResource({id}).then((response) => {
                this.project = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
